"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FINANCIAL_DATA_LENGTH = exports.FINANCIAL_TABLE_YEARS = exports.GOOGLE_MAPS_API_URL = exports.PAGE_SIGNUP_VERIFY = exports.SIGNUP = exports.LOGOUT = exports.LOGIN = exports.HOME = void 0;
exports.HOME = '/';
exports.LOGIN = '/login';
exports.LOGOUT = '/logout';
exports.SIGNUP = '/signup';
exports.PAGE_SIGNUP_VERIFY = `${exports.SIGNUP}/verify/`;
exports.GOOGLE_MAPS_API_URL = 'https://www.google.com/maps/embed/v1/place?key=';
exports.FINANCIAL_TABLE_YEARS = [1, 2, 3, 5, 10];
exports.FINANCIAL_DATA_LENGTH = 10;
